import React, { useEffect } from "react";
import { Route, Redirect, Switch } from "react-router-dom";

import Home from "views/Home.js";
import Location from "./views/Location";
import Posts from "./views/Posts";
import AboutUs from "./views/AboutUs";
import PrivacyPolicy from "./views/PrivacyPolicy";
import TermsAndConditions from "./views/TermsAndConditions";
import EmailVerification from "views/EmailVerification";
import ResetPassword from "./views/ResetPassword";
// import InstallPWA from './components/InstallPWA/InstallPWA';
import ContactUs from "./views/ContactUs";
import SignIn from "views/SignIn";
import SignUp from "views/SignUp";
import ForgotPassword from "views/ForgotPassword";
import Subscriptions from "views/Subscriptions";
import PaymentReturn from "views/PaymentReturn";
import Checkout from "views/Checkout";
import Packages from "views/Packages";
import { useDispatch } from "react-redux";
import { receiveCurrentUser } from "redux/features/auth/authSlice";
import Account from "views/Account";

const Routes = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(receiveCurrentUser());
  }, []);

  return (
    <Switch>
      <Route path="/packages" render={(props) => <Packages {...props} />} />
      <Route path="/account" render={(props) => <Account {...props} />} />
      <Route
        path="/payments/return"
        render={(props) => <PaymentReturn {...props} />}
      />
      <Route path="/checkout" render={(props) => <Checkout {...props} />} />
      <Route
        path="/forgot-password"
        render={(props) => <ForgotPassword {...props} />}
      />
      <Route
        path="/verify-email"
        render={(props) => <EmailVerification {...props} />}
      />
      <Route
        path="/subscriptions"
        render={(props) => <Subscriptions {...props} />}
      />
      <Route
        path="/reset-password"
        render={(props) => <ResetPassword {...props} />}
      />
      <Route path="/sign-up" render={(props) => <SignUp {...props} />} />
      <Route path="/sign-in" render={(props) => <SignIn {...props} />} />
      <Route path="/post" render={(props) => <Posts {...props} />} />
      <Route path="/location" render={(props) => <Location {...props} />} />
      <Route path="/about-us" render={(props) => <AboutUs {...props} />} />
      <Route
        path="/privacy-policy"
        render={(props) => <PrivacyPolicy {...props} />}
      />
      <Route
        path="/terms-and-conditions"
        render={(props) => <TermsAndConditions {...props} />}
      />
      <Route path="/contact-us" render={(props) => <ContactUs {...props} />} />
      <Route path="/" render={(props) => <Home {...props} />} />
      <Redirect to="/" />
    </Switch>
  );
};

export default Routes;
